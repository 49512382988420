@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR&family=Noto+Sans+Arabic&family=Noto+Sans+Thai&family=Sawarabi+Gothic&family=ZCOOL+XiaoWei&display=swap');

// font-family: 'IBM Plex Sans', sans-serif;
// font-family: 'Noto Sans Arabic', sans-serif;
// font-family: 'Noto Sans Thai', sans-serif;
// font-family: 'Sawarabi Gothic', sans-serif;
// font-family: 'ZCOOL XiaoWei', serif;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::-webkit-scrollbar {
        width: 0.3em;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
    }
}

.draggable-text.marked {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-width: 2px;
        border-style: dotted;
        border-color: inherit;
    }
}

.pdf-document-page {
    width: 100%;
    max-width: 100%;

    canvas {
        width: 100% !important;
        height: auto !important;
    }
}
